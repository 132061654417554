.about_section {
    width: 100%;
    height: auto;
    background: #303030;

}

.about_content {
    width: 70% !important;
}

.about_content p {
    color: lightgray;
    font-family: "Roboto", sans-serif;
    text-align: center;
    line-height: 25px;
}


@media only screen and (min-width:200px) and (max-width:576px) {
    .about_content {
        width: 100% !important;
    }

    .about_content p {
        width: 100% !important;
        line-height: 20px;

    }

    .heading_style span {
        font-size: 25px !important;
    }

}