.footer {
  height: auto;
  background-color: #1a1a1a;
  /* background-color: #3b3d42; */

}

.footer-container {

  margin: 0 auto;
  padding: 40px 5px 5px 0px !important;
  border-bottom: 0.5px solid gray;
}

.footer-section {
  padding: 20px 10px 40px 0px;
}

.logo {
  max-width: 74px;
}

.footer-section h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Josefin Sans', sans-serif;
  color: #ffffff;
}

.footer-section .menu {
  list-style: none;
}

.sub_menu {
  cursor: pointer;
  margin: 10px 0px !important
}

.footer-section .menu .sub_menu a {
  color: white !important;
  text-decoration: none;
  margin-top: 5px;
  padding-left: 5px;
  font-size: 15px !important;
}

.footer-section p {
  line-height: 1.6;
}

.footer-section .contact-us .menu .sub_menu {
  display: flex;
  align-items: center;
  padding: 10px auto !important;
}


.about_made {
  color: whitesmoke !important;
  font-weight: 100 !important;
  font-size: 14px;

}

.company-info p {
  color: whitesmoke !important;
  font-family: Arial, sans-serif;
  font-size: 15px;
}

.bottom_section img {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.footer_logo_style {
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding-left: 5px;
}

.powered_by img {
  width: 100px;
  height: 40px;
}

.powered_by_text {
  color: whitesmoke;
  font-size: 12px;
}

@media only screen and (min-width:200px) and (max-width:576px) {
  .footer-section .menu .sub_menu a {
    font-size: 13px !important;
  }

  .footer-section h3 {
    font-size: 17px;
  }


}