.section_three {
    width: 100%;
    background-image: url("../../assets/Climate_background.jpg");
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px !important;
    position: relative;
}

.esg_section {
    width: 100%;
    height: 600px;
    height: auto;
    background-color: #202020
}

.esg_left {
    width: 100%;
}

.esg_left img {
    width: 100%;
    border-radius: 5px;
}

.heading_style span {
    border-bottom: 1.5px solid yellow !important;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 30px;
    padding-bottom: 5px;
}

.esg_content ul {
    padding-left: 0px !important;
}

.esg_content ul li {
    list-style: none;
    color: white;
    opacity: 0.9;
    font-family: sans-serif;
    font-size: 15px;
    margin-bottom: 12px !important;
}




.esg_left img.default {
    opacity: 1;
    display: block;
    transition: 0.3s;

}

.esg_left img.on_hover {
    opacity: 0;
    display: none;
    transition: 0.3s;

}

.esg_left:hover img.default {
    opacity: 0;
    display: none;
    transition: 0.3s;


}

.esg_left:hover img.on_hover {
    display: block;
    opacity: 1 !important;
    transition: 0.3s;

}

@media only screen and (min-width:200px) and (max-width:576px) {

    .heading_style span {
        font-size: 24px !important;
    }


}

@media only screen and (min-width:200px) and (max-width:768px) {
    .section_three {
        background-size: contain !important;
        height: 320px !important;
    }

}