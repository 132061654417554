.carousel-item {
    width: 100% !important;
    position: relative;
    height: 600px !important;
}

.carousel-item-image img {
    width: 100% !important;
    height: 100% !important;
    background-size: cover;
    top: 0px;
    left: 0px;
    position: absolute;
}



.carousel-indicators button {
    width: 12px !important;
    height: 12px !important;
    border-radius: 50% !important;
}

.carousel-caption {
    top: 0rem !important;

}

.carousel-caption p {
    font-size: 17px;

}

.content_div {
    width: 100%;
    height: 100%;
    padding-left: 100px;
    padding-right: 100px;

}

.carousel-indicators button {
    background-color: lightgray;
    opacity: 0.8;
}

.carousel-indicators .active {
    background-color: yellow !important;
    opacity: 0.1;

}

.black_overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.7)
}

@media only screen and (min-width:200px) and (max-width:991px) {
    .carousel-caption {
        top: 8rem !important;

    }

}

@media only screen and (min-width:200px) and (max-width:768px) {
    .carousel-item {
        height: 320px !important;
    }

}

@media only screen and (min-width:200px) and (max-width:576px) {
    .carousel-item {
        height: 220px !important;
    }

}