.logo_style {
    width: 60px;
    height: 70px;
}

.offcanvas_logo_style {
    width: 50px;
    height: 60px;
}


.header_bg {
    background-color: #fff;
}


.nav-item {
    margin-left: 1.2rem !important;
    margin-right: 1.2rem !important;
}



.nav-item {
    /* color: #909090 !important; */
    color: #000 !important;
    font-size: 16px !important;
    position: relative;
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
    overflow: hidden;
    font-weight: 0 !important;
    cursor: pointer;


}

.nav-item::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 1.5px;
    background-color: yellow;
    transition: width 0.3s ease;
}

.nav-link:hover::before {
    width: 100%;
}

.nav-link:hover {
    color: #000 !important;
}

.active_nav {
    text-decoration: none;
    color: #000 !important;
    font-weight: 500 !important;
}

.active_nav::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.5px;
    background-color: yellow;
    transition: width 0.3s ease;
}

.nav-item:hover {
    border-bottom: 1.5px solid yellow;
}

body {
    position: relative;
}

@keyframes moveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.bottom_bar {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #333;
    border-radius: 50%;
    background-color: #f36d21;
    z-index: 2;
    cursor: pointer;
}

.inner_bottom {
    animation: moveUpDown 2s ease-in-out infinite;

}

.navbar-toggler {
    border: 1px solid #000 !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.portfoliyo_style {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    padding-top: 8px;

}