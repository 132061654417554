body {
    position: relative;
    scroll-behavior: smooth;
}

@keyframes moveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.bottom_bar {
    position: fixed;
    bottom: 75px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 18px;
    border-radius: 50%;
    background-color: rgb(144, 148, 153);
    z-index: 2 !important;
    cursor: pointer;
}

.bottom_inner {
    animation: moveUpDown 2s ease-in-out infinite;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: lightgray !important;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #707070 !important;
    border-radius: 10px;
}