.services {
    width: 100%;
    height: auto;
    background-color: #303030;
}

.section_one {
    width: 100%;
    background-image: url("../../assets/FoodAgri_backround.jpg");
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px !important;
    position: relative;
}



.service_card {
    width: 100%;
    height: auto;
    border-radius: 5px;
    overflow: hidden;
}


.service_card .img_div {
    width: 100%;
    height: 200px !important;
}

.service_card .img_div img {
    width: 100%;
    height: 100%;
    transition: 1s ease-in-out;
}

.srvice_content_div {
    width: 100%;
    height: 140px;
    padding: 12px 12px 10px 10px;
    background-color: #505050;
    position: relative;
    overflow: hidden;
    z-index: 99 !important;
}

.srvice_content_div::before {
    content: '';
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 0px;
    height: 0px;
    background-color: white !important;
    transition: 0.5s ease-in-out;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    z-index: -1 !important;
    transform: translate(70);
}


.service_card:hover .srvice_content_div::before {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: #fff !important;
    transform: translate(10);
}


.service_card .srvice_content_div .team-classic-name {
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none !important;
    margin-bottom: 3px !important;
}

.service_card .srvice_content_div .team-classic-status ul {
    padding-left: 18px !important;
    margin-top: 10px;
}


.service_card .srvice_content_div .team-classic-status ul li {
    line-height: 1.5;
    color: #fff;
    font-size: 14.5px;
    letter-spacing: .025em;
    margin-bottom: 5px;
}


.service_card:hover .team-classic-name,
.service_card:hover .srvice_content_div .team-classic-status ul li::marker,
.service_card:hover .srvice_content_div .team-classic-status ul li {
    color: #000 !important;
    transition: 0.5s ease-in-out;
}

.service_card .srvice_content_div .team-classic-status ul li::marker {
    color: yellow;
    font-weight: 700;
}

.service_card .img_div img.img_div_default {
    transition: 1s ea;
    opacity: 1;
    display: block;

}

.service_card .img_div img.img_div_on_hover {
    transition: 1s ea;
    opacity: 0;
    display: none;
}

.service_card:hover .img_div img.img_div_default {
    transition: 1s ea;
    opacity: 0;
    display: none;
}

.service_card:hover .img_div img.img_div_on_hover {
    transition: 1s ea;
    display: block;
    opacity: 1 !important;
}

.service_section .slick-track {
    display: flex !important;
    gap: 10px !important;
}

.slick-prev {
    left: -30px !important;
}

.slick-next {
    right: -30px !important;
}

@media only screen and (min-width:200px) and (max-width:576px) {
    .slick-prev {
        left: -5px !important;
    }

    .slick-next {
        right: -15px !important;
    }

    .heading_style span {
        font-size: 25px !important;
    }
}

@media only screen and (max-width: 768px) {
    .section_one {
        background-size: contain;
        height: 320px !important;
    }
}