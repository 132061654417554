.contact {
    width: 100%;
    height: auto;
    background-image: url("../../assets/backgroundImage.jpg");
    background-size: cover !important;
}

.heading_style span {
    color: #fff !important;
    border-bottom: 1.5px solid #fff;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 30px;
    padding-bottom: 5px;
}

.title_style {
    padding-left: 25px;
    padding-right: 5px;
    font-size: 25px;
    color: white;
}

.contact_section {
    width: 80%;
    margin: auto;



}

.contact_left {
    width: 100%;
    height: 300px;

}

.contact_title {
    font-family: "Oswald", Sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.285em;
    color: #fff;
}

.contact_heading {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.3333em;
    border-bottom: 2px solid lightgray;
    padding-bottom: 5px;
    color: #fff;
    border-bottom: 1.5px solid yellow;
}

.company_name {
    color: #fff;
    width: 50px;

}

.number_style a {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins" sans-serif;
    text-decoration: none;
    color: #fff !important;
}



.contact_input_style {
    border: none !important;
    border-radius: 4px !important;
    padding: 7px 10px !important;
    box-shadow: 0 0.08rem 0.3rem rgba(0, 0, 0, 0.05);
    border: 1px solid #fff !important;
    background-color: #555 !important;
    color: #fff !important;


}

.contact_input_style:focus {
    border: 1px solid none !important;
    border-bottom: 1px solid #fff !important;
    box-shadow: none !important;
}

.contact_input_style::placeholder {
    font-size: 14px;
    color: #fff !important;
}

.text_area {
    height: 100px;
}

.contact_button {
    padding: 7px 30px !important;
    box-shadow: none !important;
    border: 1px solid #fff !important;
    background-color: #555 !important;
    color: #fff !important;


}

.contact_button:hover {
    background-color: gray !important;


}



.bottom_message {
    position: fixed;
    bottom: 15px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 18px;
    border-radius: 50%;
    background-color: rgb(144, 148, 153);
    z-index: 2 !important;
    cursor: pointer;
}







.overlay {
    position: fixed;
    bottom: 60px;
    right: 15px;
    z-index: 9111 !important;
}

.modalClose {
    visibility: visible;
    opacity: 1;
    display: block;

}

.popup {
    width: 400px;
    height: auto;
    background: #fff;
    border-radius: 5px;
    position: relative;
    transition: all 5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 18px;
    overflow: hidden;
    border-radius: 10px;
}

.popup .close {
    position: absolute;
    top: 0px;
    right: 10px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    cursor: pointer;
}


.modal_header {
    padding: 20px 10px;
    text-align: center;
    background: #f7992b;
    position: relative;
    background: #333;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 20px 0px
}

.modal_header span {
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.contact_inner {
    height: 380px;
    padding: 20px;
    overflow-y: auto;
    margin-bottom: 10px;

}

.modal_header_inner {
    position: absolute;
    width: 80%;
    left: 10%;
    right: 10%;
    bottom: -20px;
    border-radius: 10px;
    background: rgba(144, 148, 153, 0.8);
    padding: 8px 20px;
}

.modal_header_inner p {
    color: white;
    font-size: 15px;
    font-weight: 500;
}

.type_text_style {
    font-size: 17px;
}

.lable_style {
    font-size: 14px;
}

.checkbox_style {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 3px;
    left: 0px;
}

.error_text {
    font-size: 13px !important;
}

@media only screen and (min-width:200px) and (max-width:576px) {
    .contact_heading {
        font-size: 20px;

    }

}