.section_two {
    width: 100%;
    background-image: url("../../assets/Financial_Services_Backgound1.jpg");
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px !important;
    position: relative;
}

.investmentStrategy {
    width: 100%;
    height: auto;
    background-color: #303030;
}

.detail_card {
    width: 100%;
    height: 180px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.8s;
    box-shadow: 0px 0px 1px 1px #fff;
    position: relative;
    overflow: hidden;
    transform: scale(1);
    transition: 0.5s;
}

.detail_card:hover {
    transform: scale(1.05);
    transition: 0.5s;
}

.detail_card::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    width: 0px;
    height: 0px;
    background-color: #000 !important;
    transition: 0.5s ease-in-out;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    z-index: 1 !important;
    transform: translate(70);


}


.detail_card:hover::before {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: #000 !important;
    transform: translate(10);
    transition: 0.8s;

}

.detail_card h2 {
    color: #000;
    line-height: 25px;
    font-family: sans-serif;
    text-align: center;
    text-transform: capitalize;
    /* font-family: 'Roboto Condensed', sans-serif; */
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    transition: 0.8s !important;

}


.detail_card:hover h2 {
    color: yellow !important;
    transition: 0.8s;
    z-index: 99 !important;

}

@media only screen and (min-width:200px) and (max-width:768px) {
    .section_two {
        background-size: contain;

        height: 320px !important;
    }

}