@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.section_four {
    width: 100%;
    background-image: url("../../assets/Team_backgound.jpg");
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px !important;
    position: relative;
}

.teams_section {
    width: 100%;
    height: auto;
    background-color: #3b3d42;
    /* background-color: #eee; */
}

.heading_style {
    font-weight: bold;
    /* #3b3d42 */
}

.heading_style span {
    border-bottom: 1.5px solid yellow !important;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 30px;
    padding-bottom: 5px;
}

.title_style {
    font-size: 25px;
    color: white;
    padding-left: 0px !important;
    padding-bottom: 5px;
    border-bottom: 1.5px solid yellow;
}

.team-classic {
    width: 95%;
    height: 360px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    background-color: lightgray;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    padding-top: 30px;

}

.more_info_hover {
    opacity: 0 !important;
}

.team-classic:hover {
    background-color: white;
}

.team-classic:hover .more_info_hover {
    opacity: 1 !important;
    background-color: black !important;
    color: #fff !important;
    transition: 0.3s;
}

.team-classic-figure {
    width: 210px;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    position: relative;
}

.team-classic-figure img {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;

}

.team-classic .team-classic-figure img.default {
    opacity: 1;
    display: block;
    transition: 0.3s;
}

.team-classic .team-classic-figure img.on_hover {
    opacity: 0;
    display: none;
    transition: 0.3s;

}

.team-classic:hover .team-classic-figure img.default {
    opacity: 0;
    display: none;
    transition: 0.3s;


}

.team-classic:hover .team-classic-figure img.on_hover {
    display: block;
    opacity: 1 !important;
    transition: 0.3s;

}



.team-classic-caption {
    position: absolute;
    overflow: hidden;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 25%;
    z-index: 1;
    transition: 0.5s;
}

.team-classic-caption-left {
    padding: 10px;
    width: 100%;

}

.team-classic-caption-right {
    position: absolute;
    right: 7px;
    top: 8px;

}

.team-classic-caption::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: lightslategray;
    transform-origin: 50% 100%;
    z-index: -1;
    transition: 0.5s;

}

.team-classic-name {
    color: #fff;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
}

.team-classic-name a,
.team-classic-name a:focus,
.team-classic-name a:active {
    color: inherit;
}

.team-classic-name a:hover {
    color: #a3b8ff;
}


.team-classic-status {
    line-height: 1.5;
    color: #fff;
    font-size: 14.5px;
    letter-spacing: .025em;
}

.slick-slider {
    width: 97%;

}

.slick-dots {
    bottom: -40px !important;
}

.slick-dots li button:before {
    font-size: 10px !important;
    color: white !important;
    opacity: 0.8 !important;
}

.slick-dots li.slick-active button:before {
    color: yellow !important;
    font-size: 12px !important;
    opacity: 1 !important;
}


.teams_section .slick-prev {
    left: -40px !important;
}

.teams_section .slick-next {
    right: -25px !important;
}

.slick-prev,
.slick-next {
    width: 40px !important;
    height: 40px !important;
    z-index: 1;
}

.slick-prev:before,
.slick-next:before {
    font-size: 25px !important;
    font-weight: 0 !important;
}



.slick-dots li button:before {
    font-size: 12px;
    color: white;
    opacity: 1;
}

.slick-dots li.slick-active button:before {
    color: red;
}


.founderCard {
    width: 100%;
    height: 250px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 25px;
    position: relative;
    transition: 1s;
    overflow: hidden;
}

.founderCardOverlay {
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, 0.9);
    position: absolute;
    bottom: -100%;
    left: 0px;
    border-radius: 5px;
    transition: 0.5s;
    overflow: hidden;
}

.overlay_text {
    font-size: 13px !important;
    font-weight: 0 !important;
    color: white;
}

.founderCard:hover {
    transition: 1s;
    background-color: rgba(17, 17, 17, 0.5) !important;
    border: 2px solid lightgray;
    border-radius: 5px;
}

.founderCard:hover .founder_heading {
    color: white !important;

}

.founderCard:hover .more_info {
    background-color: white !important;
    color: #000 !important;

}

.founderCard:hover .founder_title {
    color: white !important;

}

.image_div {
    width: 130px;
    height: 130px;
    position: relative;
}

.image_div img {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 50%;
    transition: opacity 0.3s ease-in-out;
    border: 1px solid lightgray !important;
}

.founderCard .image_div img.default {
    opacity: 1;
    display: block;
    transition: 0.3s;
}

.founderCard .image_div img.on_hover {
    opacity: 0;
    display: none;
    transition: 0.3s;

}

.founderCard:hover .image_div img.default {
    opacity: 0;
    display: none;
    transition: 0.3s;


}

.founderCard:hover .image_div img.on_hover {
    display: block;
    opacity: 1 !important;
    transition: 0.3s;

}

.founder_heading {
    color: black;
    font-size: 20px;
}

.founder_title {
    color: #000;
    opacity: 0.8s;
}

.more_info {
    background-color: black;
    width: 30px;
    height: 30px;
    font-weight: bold;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer !important;
    border: none !important;
}

.modal_height {
    height: 500px;
    overflow: hidden;
    /* overflow-y: auto !important; */
}

.modal_content {
    height: 220px;
    overflow-y: auto;
}

.modal_data p {
    font-size: 15px;

}



.modal_image img {
    width: 170px;
    height: 160px;
}

.modal_inner {
    width: 100%;
}

.modal_content ul {
    padding-left: 15px !important;
}

.modal_content ul li {
    color: #000;
    font-size: 15px;

    margin: 7px auto;
    font-family: "Roboto", sans-serif;

}


.modal_content ul li::marker {
    color: gold;
    font-size: 16px;
}

.modal_image span {
    font-size: 22px;
    font-weight: 500;
    font-family: 'MontserratRegular';
}

.close_modal {
    cursor: pointer;
}

.linkdin_style {
    width: 35px !important;
    height: 35px !important;
    cursor: pointer !important;
}



@media only screen and (min-width:200px) and (max-width:991px) {
    .team-classic {
        width: 100% !important;

    }

    .title_style {
        padding-left: 0px !important;
        padding-right: 0px !important;
        font-size: 22px !important;
        color: white;
    }

}

@media only screen and (min-width:200px) and (max-width:768px) {
    .section_four {
        background-size: contain;
        height: 320px !important;
    }

}

@media only screen and (min-width:200px) and (max-width:576px) {

    .heading_style span {
        font-size: 24px !important;
    }

    .title_style {

        font-size: 20px !important;
    }


}