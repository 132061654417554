.portfolio {
    width: 100%;
    background-color: #000;

}

.portfolio_section {
    width: 100%;
    height: 400px !important;

}

.portfolio_section img {
    width: 100%;
    height: 100%;
}



.portfolio_section img.img_div_default {
    opacity: 1;
    display: block;
    transition: 0.3s;
}

.portfolio_section img.img_div_on_hover {
    opacity: 0;
    display: none;
    transition: 0.3s;

}

.portfolio_section:hover img.img_div_default {
    opacity: 0;
    display: none;
    transition: 0.3s;


}

.portfolio_section:hover img.img_div_on_hover {
    display: block;
    opacity: 1 !important;
    transition: 0.3s;

}